@mixin scrollbar($color, $bgcolor, $width, $radius){
	scrollbar-width: thin !important;
  	scrollbar-color: $color $bgcolor;

	&::-webkit-scrollbar {
	  width: $width;
	}
	&::-webkit-scrollbar-track {
	  background: $bgcolor;
	}
	&::-webkit-scrollbar-thumb {
	  background-color: $color ;
	  border-radius: $radius;
	  border: 1px solid $bgcolor;
	}
	&::-webkit-resizer {
	  width: $width;
	  background: $bgcolor;	
	}
}

@mixin icon_accordion{
	&:after{
		display:block;
		content: "\45";
	//	background: url("../img/arrow.gif") no-repeat bottom left;
		width: 15px;
		height: 15px;
		position: absolute;
		top:0;
		right:0;
		transform: rotate(90deg);
		font-family: 'ElegantIcons';
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		line-height: 6px;
		overflow: hidden;
	}
}


@mixin item{
margin:0;
padding:0 15px;	
cursor:pointer;

	.add_wl{
		display: block;
		position: absolute;
		top:0px;
		right:20px;
		z-index: 10;
		color:$grigio;
		&:hover{
			color:$grigio_scuro;
		}
	}
	.frame{
		display: block;
		overflow: hidden;	
		padding:0 0px 5px;
		text-align: left;
			
		position: relative;
		transition: .5s;
		transition-delay:.15s;
		
		
		
		.out_stock, .sale, .last_arrive{
			font-size:0.8em;
			font-weight: 600;
			text-transform: uppercase;
			color:$bianco;
			background: $grigio;
			padding: 3px 5px;
			position: absolute;
			top:0;
			left: 0;
			z-index: 90;
			border-bottom-right-radius: 10px;
		}
		.last_arrive{
			background:$arancio;
		}
		.sale {
			background: $verde_acqua;
		}
	
		.box_img{
			border:solid 1px $bianco;
			width:100%;
			height: 100%;
			max-width: 360px;
			margin:0 auto 20px;
			padding: 0;
			overflow: hidden;
			position: relative;
			
		}
		.gal_img{
			
			width:100%;
			height: 100%;
			overflow: hidden;
			background:$grigio_chiaro;
			.swiper-slide{
				background:$grigio_chiaro;
			}
			img{
				width:100%;
				height: auto;
				 mix-blend-mode: multiply;
			}
		}
		.cnt{
			text-transform: inherit; 
			margin:0 auto 10px; 
			padding: 0; 
			text-align: center; 
			font-size: 0.9em;
			max-width: 360px;
			span{
				display:block;
			}
			.marca{
				display: block;
				width:100%;
				font:400 1.5em 'Staatliches', cursive ;
				letter-spacing: 0;
				font-weight: 500;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 0 5px;
				text-transform: lowercase;
				&:first-letter{
					text-transform: uppercase;
				}	
				
			}
			
			.prodotto, .taglia_catalogo{
				display: block;
				width:100%;
				margin:0 ;	
				font-size:1em;
				line-height: 1.2em;
				height: 2.2em;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 0 10px;
				text-transform: lowercase;
				&:first-letter{
					text-transform: uppercase;
				}
				&.taglia_catalogo{
					display: none;
				}	
			}
			.prezzo{
			position:relative;
			bottom:0;
			left: 0;
			width: 100%;
			padding:0;
			margin: 0 0 15px;
			font-size:1em;	
			span{
				display: inline-block;
				position: relative;
				width:auto;
				margin: 0 5px 0 0;
				
				@media screen and (max-width:$break-normal ){
					display: block;
					margin: 0 0 5px 0;
					height: 1em;
				}
				
			}
			del{
				color:$grigio;
			}
			i{
				color:$grigio;
				font-weight: 500;
				font-style: normal;
				color:$verde_acqua;
			}
			em{
				display: inline-block;
				font-style: normal;
				letter-spacing: 0.15em;
				font-weight: 600;
				color:$nero;
			}
		}
			.prezzo_nd{font-weight: 600; color:$grigio;}
			
		}
		
		&:hover{
			.box_img{
				border:solid 1px $grigio_chiaro;
			
			}
			.marca{
				color:$verde_acqua !important;
			}
			.taglia_catalogo{
				display: block !important;
			}
			.prodotto{
				display: none;
			}

		}


	}
}
