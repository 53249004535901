@import "_variables";
@import "_mixins"; 

#popup_box{
	display: block;
	background: $bianco;
	position:fixed; 
	top:25%;
	left:50%;
	transform: translateX(-50%);
	z-index:11000;
	width:100%;
	max-width: 450px;
	
	overflow: hidden;
	padding: 10px;
	box-shadow: 0 0 10px 0 rgba($nero, 0.2);
	border:solid 1px $nero;
	text-align: center;

	#popup_cnt{
		padding: 0px 0 0;
		position: relative;
		z-index: 0;
		width:100%;
		img{
		width:100%;
		height: auto;
		position:relative;
		margin:0 0 15px;	
		}
		.popup_txt{
			width:100%; 
			position: absolute;
			top:50%;
			left: 0;
			transform: translateY(-50%);
			//text-shadow: 2px 2px 2px rgba($grigio, 0.8);
		}
		 h2 {
			width:100%; 
			text-align: center ;
		//	background: $bianco; 
			text-transform: uppercase;
			color:$nero;
			max-height: 2.2em;
			overflow: hidden;
		
			 
			&:before{
				display:none;
			}
		}
		
		
		
		h6{
			color:$nero;
			font-weight: 100;
			font-style: italic;
			text-transform: lowercase;
			margin-top: 2.5em;
			width:100%; 
			text-align: center ;
		//	background: $bianco;
			
		}
	}
	.input-box{
		width:100%;
		padding: 15px;
		position:relative;
		text-align:center;
		background: $bianco;
		input{
			padding-left:5px;
			display:block;
			width:100%;
			text-align: center;
			margin-bottom: 30px;
		}
		button{
			display:inline-block;
			width:60%;
			text-align:center;
			color:$nero;
			background:$bianco;
			font-weight:600;
			border:solid 1px $nero;
			line-height: 1.4em;
			border-radius:25px;
			//padding-bottom: 1px;
		}
	}
	#close_pop{
		cursor:pointer;
		font-weight:bold;
		position:absolute;
		top:20px;
		right:25px;
		color:$nero;
		font-size:2em;
		padding:0;
		z-index: 9000;
	}
	&.popup_no_img{
		#popup_cnt{
			
		padding: 0 0 60%;
		position: relative;
		z-index: 0;
		width:100%;
		
		
		img{display:none !important;		}
		
		 h2 {
			width:100%; 
			text-align: center ;
		//	background: $bianco; 
			text-transform: uppercase;
			color:$nero;
			
			overflow: hidden;
			font-size: 3.5em; 
			&:before{
				display:none;
			}
		}
		
		
		
		h6{
			color:$nero;
			font-weight: 100;
			font-style: italic;
			text-transform: lowercase;
			margin-top:15px;
			width:100%; 
			text-align: center ;
		//	background: $bianco;
			
		}
	}
	}
	
	
	@media screen and (max-width:$break-large_plus){
		max-width: 350px;
		top:15%;
		#popup_cnt{
			width:330px;
			
			h2{
				margin:50px 0 0 0 !important;
				font-size:3em !important;
			}
			h6{
				margin:0 !important;
			}
		}
		button{
				width:80% !important;
			}
		
	}
	@media screen and (max-width:$break-large) and (orientation: landscape){
		#popup_cnt{
			padding: 0 0 50% !important;
			overflow: hidden;
			img{
				width:100%;
				position: absolute;
			}
			
			h2{
				margin:50px 0 0 0 !important;
				font-size:3em !important;
			}
			h6{
				margin:0 !important;
			}
		}
	}
}